import type {FetchQueryOptions, QueryKey, InvalidateQueryFilters} from 'react-query';
import {QueryClient} from 'react-query';
import {GraphQLClient} from 'graphql-request';

import {environment} from 'config/environment';

import type {GraphQLError, RequestDocument, Variables} from 'graphql-request/dist/types';
import {accessTokenManager} from 'services/AccessTokenManager';

export {gql} from 'graphql-request';

interface ApolloGraphQLError extends Omit<GraphQLError, 'path'> {
  extensions: {
    code: string;
  };
}

export interface GraphqlCoreError {
  message: string;
  response: {
    errors: ApolloGraphQLError[];
  };
}

interface RequestQueryOptions {
  requestHeaders?: HeadersInit;
  cache?: {
    key: string;
    staleTime: number;
  };
}

interface FetchQueryParams<TResult, TVariables> extends FetchQueryOptions<TResult> {
  queryKey: QueryKey;
  queryDocument?: RequestDocument;
  queryVariables?: TVariables;
}

const gqlClientInstance = new GraphQLClient(environment.settings.apiUrl.coreApiGraphQl, {
  headers: {
    'Content-Type': 'application/json',
    'X-Proxy-Headers': 'true'
  },
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // global 20s staleTime to deduplicate identical requests in this time frame
      staleTime: 1000 * 20,
    },
  },
});

export const gqlRequest = async <T = any, V = Variables>(
  query: RequestDocument,
  variables?: V,
): Promise<T> => {
  const accessToken = await accessTokenManager.getAccessToken()
  gqlClientInstance.setHeader('authorization', `Bearer ${accessToken}`);

  return gqlClientInstance.request<T, V>(query, variables);
};

export const fetchQuery = <TResult, TVariables = Variables>({
  queryKey,
  queryDocument,
  queryVariables,
  ...options
}: FetchQueryParams<TResult, TVariables>): Promise<TResult> => {
  const queryFn = () => gqlRequest<TResult, TVariables>(queryDocument, queryVariables);

  return queryClient.fetchQuery({queryKey, queryFn, ...options});
};

export const prefetchQuery = <TResult, TVariables = Variables>({
  queryKey,
  queryDocument,
  queryVariables,
  ...options
}: FetchQueryParams<TResult, TVariables>): Promise<void> => {
  const queryFn = () => gqlRequest<TResult, TVariables>(queryDocument, queryVariables);

  return queryClient.prefetchQuery({queryKey, queryFn, ...options});
};

export const invalidateQuery = (filters: InvalidateQueryFilters) => {
  queryClient.invalidateQueries(filters);
};

export class CoreClient {
  private static instance: CoreClient;

  private constructor() {}

  static getInstance() {
    if (!this.instance) {
      this.instance = new CoreClient();
    }

    return this.instance;
  }

  async request<T = any, V = Variables>(
    query: RequestDocument,
    variables?: V,
    options?: RequestQueryOptions,
  ): Promise<T> {
    try {
      const accessToken = await accessTokenManager.getAccessToken()
      gqlClientInstance.setHeader('authorization', `Bearer ${accessToken}`);
  
      const {requestHeaders, cache} = Object.assign({}, options);
  
      if (cache) {
        const {key, ...cacheOptions} = cache;
  
        const response = await queryClient.fetchQuery(
          [key, variables],
          () => gqlClientInstance.request<T, V>(query, variables, requestHeaders),
          cacheOptions,
        );

        return response;
      } else {
        const response = await gqlClientInstance.request<T, V>(query, variables, requestHeaders);

        return response;
      }
    } catch (error) {
      const unauthorizeError = error?.response?.errors?.some((error: ApolloGraphQLError) => {
        return error?.extensions?.code === 'UNAUTHENTICATED';
      })

      if (unauthorizeError) {
        await accessTokenManager.redirectSignInPage()
      }

      throw error;
    }
  }
}
